import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authGuard } from "../store";

const routes: Array<RouteRecordRaw> = [
  // Common routes
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/LoginPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/RegisterPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/register-auth",
    name: "register-auth",
    component: () => import("../views/auth/RegisterAuthPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("../views/auth/ChangePassword.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("../views/auth/RegisterPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/conditions-utilisation",
    name: "conditions-utilisation",
    component: () => import("../views/auth/RegisterPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/",
    name: "dashboard",
    component: () => import("../views/DashboardPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("../views/ChatPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/ProfilePage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/appel-offre",
    name: "appel-offre",
    component: () => import("../views/AppelOffre.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import("../views/projects/ListProjects.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/project/:id",
    name: "project",
    component: () => import("../views/projects/ProjectPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/add-project",
    name: "add-project",
    component: () => import("../views/projects/AddProject.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/videos",
    name: "videos",
    component: () => import("../views/videos/ListVideos.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/add-video",
    name: "add-video",
    component: () => import("../views/videos/AddVideo.vue"),
    beforeEnter: authGuard,
  },

  // Fournisseur routes
  {
    path: "/products",
    name: "products",
    component: () => import("../views/products/ListProducts.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/product/:id",
    name: "product",
    component: () => import("../views/products/ProductPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/add-product",
    name: "add-product",
    component: () => import("../views/products/AddProduct.vue"),
    beforeEnter: authGuard,
  },

  // P-Travaux, P-Tce routes
  {
    path: "/prestation-travaux",
    name: "prestation-travaux",
    component: () => import("../views/travaux/PrestationTravaux.vue"),
    beforeEnter: authGuard,
  },

  // P-Services routes
  {
    path: "/prestation-service",
    name: "prestation-service",
    component: () => import("../views/services/PrestationService.vue"),
    beforeEnter: authGuard,
  },

  // Catch-all route
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
