import axios from "axios";
import store from "@/store";

const baseURL = "https://backend.aureght.com/api/seller";

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use((config) => {
  const token = store.state.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.Accept = "application/json";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      console.error("log out");
      await store.dispatch("logoutWithoutAPI"); // Call logout action from the store
    }
    return Promise.reject(error);
  }
);

const authAPI = {
  login: (credentials: any) => axiosInstance.post("login", credentials),
  changePassword: (credentials: any) =>
    axiosInstance.post("/user/change-password", credentials),

  registerFournisseur: (credentials: any) =>
    axiosInstance.post("fournisseur/register", credentials),
  registerPT: (credentials: any) =>
    axiosInstance.post("p-travaux/register", credentials),
  registerPTCE: (credentials: any) =>
    axiosInstance.post("p-tce/register", credentials),
  registerPS: (credentials: any) =>
    axiosInstance.post("p-services/register", credentials),

  registerAuthFournisseur: (credentials: any) =>
    axiosInstance.post("fournisseur/register-auth", credentials),
  registerAuthPT: (credentials: any) =>
    axiosInstance.post("p-travaux/register-auth", credentials),
  registerAuthPTCE: (credentials: any) =>
    axiosInstance.post("p-tce/register-auth", credentials),
  registerAuthPS: (credentials: any) =>
    axiosInstance.post("p-services/register-auth", credentials),

  profil: () => axiosInstance.get("profil"),
  getUserRole: () => axiosInstance.get("/user/role"),
  getFirstLogin: () => axiosInstance.get("/user/first-login"),
  logout: () => axiosInstance.post("logout"),
};

const produitsCategoriesAPI = {
  getAll: () => axiosInstance.get("produit-categories"),
};

const produitsAPI = {
  getAll: () => axiosInstance.get("produits"),
  getByID: (productID: number) => axiosInstance.get(`produits/${productID}`),
  latest: () => axiosInstance.get("produits/latest"),
  search: (params: any) => axiosInstance.get("produits/search", { params }),
  updateProduct: (productID: number, formData: any) =>
    axiosInstance.post(`produits/update/${productID}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  addProduct: (formData: any) =>
    axiosInstance.post("produits", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteProduct: (id: any) => axiosInstance.delete(`produits/${id}`),
};

const pTravauAPI = {
  getAllCategories: () => axiosInstance.get("travaux-categories"),
  getPTCategories: () =>
    axiosInstance.get("travaux-categories/current-pt-categories"),

  addCategories_old: (categoryIds: { travaux_category_ids: any }) =>
    axiosInstance.post("travaux-categories/add-categories", categoryIds),

  addCategories: (categories: any) =>
    axiosInstance.post("travaux-categories/add-categories", { categories }),

  deletePTCategory: (id: any) =>
    axiosInstance.delete(`travaux-categories/${id}`),
};

const travauCategoriesAPI = {
  getChildrens: () => axiosInstance.get("travaux-categories/childrens"),
};

const pServiceAPI = {
  getAllServices: () => axiosInstance.get("services"), // Fetch all services
  getPServices: () => axiosInstance.get("services/current-ps-services"), // Fetch services for the current PService
  addServices: (serviceIds: { service_ids: number[] }) =>
    axiosInstance.post("services/add-services", serviceIds),
  deleteService: (id: any) => axiosInstance.delete(`services/${id}`), // Delete a service
};

const countriesAPI = {
  getAll: () => axiosInstance.get("countries"),
};

const contactsAPI = {
  getAll: () => axiosInstance.get("contacts"),
};

const chatAPI = {
  loadMessages: (id: any) => axiosInstance.get(`messages/${id}`),
  sendMessage: (contactId: number, formData: any) =>
    axiosInstance.post(`messages/send/${contactId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};

const projectsAPI = {
  getAll: () => axiosInstance.get("projects"),
  getByID: (projectID: number) => axiosInstance.get(`projects/${projectID}`),
  update: (projectID: number, formData: any) =>
    axiosInstance.post(`projects/update/${projectID}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  add: (formData: any) =>
    axiosInstance.post("projects", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id: any) => axiosInstance.delete(`projects/${id}`),
};

const videosAPI = {
  getAll: () => axiosInstance.get("videos"),
  getByID: (id: number) => axiosInstance.get(`videos/${id}`),
  update: (id: number, formData: any) =>
    axiosInstance.post(`videos/update/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  add: (formData: any) =>
    axiosInstance.post("videos", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id: any) => axiosInstance.delete(`videos/${id}`),
};

export default {
  authAPI,
  produitsCategoriesAPI,
  countriesAPI,
  pTravauAPI,
  travauCategoriesAPI,
  pServiceAPI,
  produitsAPI,
  contactsAPI,
  chatAPI,
  projectsAPI,
  videosAPI,
};
