import { createStore } from "vuex";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import Cookies from "js-cookie";

import apiServices from "../tools/apiServices";

const store = createStore({
  state: {
    isAuthenticated: !!Cookies.get("ght_token"),
    user: JSON.parse(Cookies.get("user") || "{}"),
    token: Cookies.get("ght_token"),
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
  },
  mutations: {
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        // Call the login method from authAPI with credentials
        const response = await apiServices.authAPI.login({ email, password });

        // Extract data from the response
        const data = response.data;

        // Set the token and user information in cookies with an expiry date
        const expiryDate = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000 // 24 hours from now
        );

        Cookies.set("ght_token", data.token, { expires: expiryDate });
        Cookies.set("user", JSON.stringify(data.user), {
          expires: expiryDate,
        });

        // Commit the necessary mutations
        commit("SET_TOKEN", data.token);
        commit("SET_AUTHENTICATED", true);
        commit("SET_USER", data.user);
      } catch (error) {
        console.error("Login failed:", error);
        throw error;
      }
    },
    async changePasswordFirstLogin({ commit }, { newPassword }) {
      try {
        // Call the login method from authAPI with credentials
        const response = await apiServices.authAPI.changePassword({
          new_password: newPassword,
        });

        console.log(response);
      } catch (error) {
        console.error("Login failed:", error);
        throw error;
      }
    },
    async register(
      { commit },
      {
        name,
        email,
        password,
        password_confirmation,
        company_name,
        company_description = null,
        company_phone = null,
        company_address = null,
        country_id,
        raison_social = null,
        date_creation = null,
        forme_juridique = null,
        capital = null,
        secteur_activite = null,
        registre_commerce = null,
        if_input = null,
        patente = null,
        numero_tva = null,
        numero_inscription_cnss = null,
        ice = null,
        dirigeant = null,
        representant_contact = null,
        fonction_representant = null,
        gsm_representant = null,
        email_representant = null,
        role, // role is mandatory to determine the API call
        service_ids = null, // Only for PService
        travaux_category_ids = null, // Only for PTravaux
        produit_category_ids = null,
      }
    ) {
      try {
        // Prepare the common payload
        const payload: Record<string, any> = {
          name,
          email,
          password,
          password_confirmation,
          company_name,
          company_description,
          company_phone,
          company_address,
          country_id,
          raison_social,
          date_creation,
          forme_juridique,
          capital,
          secteur_activite,
          registre_commerce,
          if: if_input,
          patente,
          numero_tva,
          numero_inscription_cnss,
          ice,
          dirigeant,
          representant_contact,
          fonction_representant,
          gsm_representant,
          email_representant,
        };

        // Add role-specific fields
        if (role === "p-service" && service_ids) {
          payload.service_ids = service_ids;
        } else if (role === "p-travaux" && travaux_category_ids) {
          payload.travaux_category_ids = travaux_category_ids;
        } else if (role === "fournisseur") {
          payload["produit_category_ids"] = produit_category_ids;
        }

        let response;
        // Call the appropriate API based on the role
        switch (role) {
          case "fournisseur":
            response = await apiServices.authAPI.registerFournisseur(payload);
            break;
          case "p-service":
            response = await apiServices.authAPI.registerPS(payload);
            break;
          case "p-tce":
            response = await apiServices.authAPI.registerPTCE(payload);
            break;
          case "p-travaux":
            response = await apiServices.authAPI.registerPT(payload);
            break;
          default:
            throw new Error("Invalid role specified");
        }

        // Extract data from the response
        const data = response.data;

        // Set the token and user information in cookies with an expiry date
        const expiryDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); // 24 hours from now
        Cookies.set("ght_token", data.token, { expires: expiryDate });
        Cookies.set("user", JSON.stringify(data.user), { expires: expiryDate });

        // Commit the necessary mutations
        commit("SET_TOKEN", data.token);
        commit("SET_AUTHENTICATED", true);
        commit("SET_USER", data.user);
      } catch (error) {
        console.error("Registration failed:", error);
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        const response = await apiServices.authAPI.logout();

        // Check if the response is successful
        if (response.status !== 200) {
          throw new Error("Logout failed");
        }

        // Perform post-logout actions
        Cookies.remove("ght_token");
        Cookies.remove("user");
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", {});
        commit("SET_TOKEN", null);
        window.location.reload();
      } catch (error) {
        console.error("Logout failed:", error);
        throw error;
      }
    },
    async logoutWithoutAPI({ commit }) {
      try {
        // Perform post-logout actions
        Cookies.remove("ght_token");
        Cookies.remove("user");
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", {});
        commit("SET_TOKEN", null);
        window.location.reload();
      } catch (error) {
        console.error("Logout failed:", error);
        throw error;
      }
    },
  },
  modules: {},
});

export default store;

export const authGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const isAuthenticated = store.getters.isAuthenticated;

  const routeName = (to.name ?? "").toString();

  type UserRole = "fournisseur" | "p-travaux" | "p-services" | "p-tce";

  const commonRoutes = [
    "profile",
    "dashboard",
    "chat",
    "appel-offre",
    "projects",
    "project",
    "add-project",
    "videos",
    "add-video",
  ];

  const roleRestrictedRoutes: Record<UserRole, string[]> = {
    fournisseur: ["products", "product", "add-product"],
    "p-travaux": ["prestation-travaux"],
    "p-services": ["prestation-service"],
    "p-tce": ["prestation-travaux"], // P-TCE has only common routes
  };

  const unauthRoutes = [
    "login",
    "register",
    "register-auth",
    "forget-password",
    "conditions-utilisation",
  ];

  if (isAuthenticated) {
    try {
      const firstLoginResponse = await apiServices.authAPI.getFirstLogin();
      const firstLogin = firstLoginResponse.data.first_login;

      console.log(firstLoginResponse);

      if (firstLogin) {
        if (routeName === "change-password") {
          next();
        } else {
          next({ name: "change-password" });
        }
      } else {
        // Fetch user role from the backend each time
        const userRoleresponse = await apiServices.authAPI.getUserRole();
        const userRole = userRoleresponse.data.role as UserRole;

        if (commonRoutes.includes(routeName)) {
          next();
        }
        // Check if the user has access to the role-specific routes
        else if (roleRestrictedRoutes[userRole].includes(routeName)) {
          next();
        } else {
          // Redirect to dashboard for unauthorized routes
          next({ name: "dashboard" });
        }
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
      // In case of error (e.g., token expired), redirect to login
      next({ name: "login" });
    }
  } else {
    // If not authenticated, only allow unauthenticated routes
    if (unauthRoutes.includes(routeName)) {
      next();
    } else {
      next({ name: "login" });
    }
  }
};
